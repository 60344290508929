import { useMemo } from "react";
import { useQuery } from "react-query";

import { GET_ORDERS_DIRECTORY_QUERY } from "../query-keys";
import { directories } from "@/services";
import { DirectoryRecord, OrderOptionEntity, TerminalEntity } from "@/domain";

export default function useOrdersDirectory() {
  const { data, error, isLoading } = useQuery(GET_ORDERS_DIRECTORY_QUERY, directories.getOrdersDirectory, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return {
    data,
    error,
    loading: isLoading,
  };
}

export const dictionaries = {
  LOCATION: "cities",
  CONTAINER_HEIGHT: "containerHeights",
  CONTAINER_TYPE: "containerTypes",
  DELIVERY_TYPE: "deliveryTypes",
  HOW_TO_CARRY: "howToCarryOptions",
  // TEMPERATURE: "dir_temperature",
  PAYMENT: "paymentTypes",
  TERMINAL: "terminals",
  STATUS: "orderStatuses",
  OPTION: "orderOptions",
} as const;

type DictionaryName = (typeof dictionaries)[keyof typeof dictionaries];

type Dictionary<T> = Record<string, T>;

type Dictionaries = {
  locations: DirectoryRecord[];
  containerTypes: DirectoryRecord[];
  containerHeights: DirectoryRecord[];
  deliveryTypes: DirectoryRecord[];
  howToCarryOptions: DirectoryRecord[];
  paymentTypes: DirectoryRecord[];
  terminals: TerminalEntity[];
  orderStatuses: DirectoryRecord[];
  orderOptions: OrderOptionEntity[];
};

const propertyNameToDictionaryName: Record<string, string> = {
  cities: "locations",
  containerHeights: "containerHeights",
  containerTypes: "containerTypes",
  deliveryTypes: "deliveryTypes",
  howToCarryOptions: "howToCarryOptions",
  paymentTypes: "paymentTypes",
  terminals: "terminals",
  orderStatuses: "orderStatuses",
  orderOptions: "orderOptions",
};

type DictionariesResult = { loading: true; data: undefined } | { loading: false; data: Dictionaries };

export function useDictionaries(): DictionariesResult {
  const { data, loading } = useOrdersDirectory();

  const dictionaries: Dictionaries | undefined = useMemo(() => {
    if (!data) return undefined;

    return Object.keys(data).reduce((acc, curr) => {
      const dictionaryName = propertyNameToDictionaryName[curr];
      // @ts-ignore
      acc[dictionaryName] = data[curr];
      return acc;
    }, {} as Dictionaries);
  }, [data]);

  if (loading)
    return {
      loading: true,
      data: undefined,
    };

  return {
    data: dictionaries!,
    loading,
  };
}

export function useDeliveryTypes() {
  return useDictionary<DirectoryRecord>(dictionaries.DELIVERY_TYPE);
}

export function useLocations() {
  return useDictionary<DirectoryRecord>(dictionaries.LOCATION);
}

export function useContainerTypes() {
  return useDictionary<DirectoryRecord>(dictionaries.CONTAINER_TYPE);
}

export function useContainerHeights() {
  return useDictionary<DirectoryRecord>(dictionaries.CONTAINER_HEIGHT);
}

export function useTerminals() {
  return useDictionary<TerminalEntity>(dictionaries.TERMINAL);
}

// export function useTemperatureModes() {
//   return useDictionary<DirectoryRecord>(dictionaries.TEMPERATURE);
// }

export function useHowToCarry() {
  return useDictionary<DirectoryRecord>(dictionaries.HOW_TO_CARRY);
}

export function usePaymentDictionary() {
  return useDictionary<DirectoryRecord>(dictionaries.PAYMENT);
}

export function useOrderStatuses() {
  const dictionary = useDictionary<DirectoryRecord>(dictionaries.STATUS);
  return {
    loading: Object.keys(dictionary).length === 0,
    data: dictionary,
  };
}

export function useOrderOptionDictionary() {
  return useDictionary<OrderOptionEntity>(dictionaries.OPTION);
}

export function useDictionary<T>(name: DictionaryName) {
  const { data, loading } = useOrdersDirectory();

  return useMemo<Dictionary<T>>(() => {
    if (!data || loading) return {};
    // @ts-ignore
    const dictionary = data[name].reduce((acc, curr) => {
      acc[curr.value] = curr;
      return acc;
    }, {} as Dictionary<T>);

    return dictionary;
  }, [data, name, loading]);
}
