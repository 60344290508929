import React, { ReactElement } from "react";
import Button, { ButtonProps } from "@/components/Button2";
import { buttonSizes, iconPlacements } from "@/components/Button2/constants";
import clsx from "clsx";

interface Props extends ButtonProps {
  icon: ReactElement;
  iconPlacement?: typeof iconPlacements.LEFT | typeof iconPlacements.TWO | typeof iconPlacements.RIGHT;
  customStyles?: string;
  secondaryIcon?: ReactElement;
}

const IconButton: React.FC<Props> = React.forwardRef<HTMLButtonElement, Props>(
  ({ children, icon, iconPlacement, size = "medium", className, customStyles, secondaryIcon, ...props }, ref) => {
    if (iconPlacement === iconPlacements.RIGHT)
      return (
        <Button size={size} className={clsx(className, "flex flex-row items-center justify-center gap-3")} {...props}>
          {children} {icon}
        </Button>
      );

    if (iconPlacement === iconPlacements.TWO)
      return (
        <Button size={size} className={clsx(className, "flex flex-row items-center justify-center gap-3")} {...props}>
          {icon} {children} {secondaryIcon}
        </Button>
      );

    if (iconPlacement === iconPlacements.LEFT)
      return (
        <Button size={size} className={clsx(className, "flex flex-row items-center justify-center gap-3")} {...props}>
          {icon} {children}
        </Button>
      );

    return (
      <Button
        {...props}
        size={size}
        className={clsx(
          className,
          `${customStyles ? customStyles : "!px-3"} ${
            size === buttonSizes.SMALL ? "!w-[40px]" : size === buttonSizes.MEDIUM ? "!w-[48px]" : "!w-[56px]"
          }`,
        )}
      >
        {icon}
      </Button>
    );
  },
);

export default React.memo(IconButton);
